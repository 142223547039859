import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"

const Internachi = ({ location }) => {
  const [first_name, setFirstname] = useState("")
  const [last_name, setLastname] = useState("")
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [phone, setPhone] = useState("")
  const [web, setWeb] = useState("")
  const [address, setAddress] = useState("")
  const [line2, setLine2] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [postal, setPostal] = useState("")
  const [nachi_id, setNachiid] = useState("")
  const [message, setMessage] = useState("")

  const formtype = "InterNACHI"

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const getValuesFromURL = () => {
    if (location.search) {
      let b64 = new URLSearchParams(location.search)
      console.log(b64.get("internachi"))
      let buf = new Buffer(b64.get("internachi"), "base64")
      let final = JSON.parse(buf.toString("ascii"))
      console.log(final.form)
      if (final.form.first_name) {
        setFirstname(final.form.first_name)
      }
      if (final.form.last_name) {
        setLastname(final.form.last_name)
      }
      if (final.form.email) {
        setEmail(final.form.email)
      }
      if (final.form.company) {
        setCompany(final.form.company)
      }
      if (final.form.phone) {
        setPhone(final.form.phone)
      }
      if (final.form.web) {
        setWeb(final.form.web)
      }
      if (final.form.address) {
        setAddress(final.form.address)
      }
      if (final.form.line2) {
        setLine2(final.form.line2)
      }
      if (final.form.city) {
        setCity(final.form.city)
      }
      if (final.form.state) {
        setState(final.form.state)
      }
      if (final.form.postal) {
        setPostal(final.form.postal)
      }
      if (final.form.nachi_id) {
        setNachiid(final.form.nachi_id)
      }
    }
  }

  if (first_name === "") {
    getValuesFromURL()
  }

  const submitContactForm = async event => {
    event.preventDefault()
    const url =
      "https://6mjv6ximbh.execute-api.us-west-2.amazonaws.com/default/isn-www-salesforce?type=" +
      formtype +
      "&firstname=" +
      first_name +
      "&lastname=" +
      last_name +
      "&company=" +
      company +
      "&email=" +
      email +
      "&phone=" +
      phone +
      "&web=" +
      web +
      "&address=" +
      address +
      "&line2=" +
      line2 +
      "&city=" +
      city +
      "&state=" +
      state +
      "&postal=" +
      postal +
      "&nachi_id=" +
      nachi_id
    let encodedurl = encodeURI(url)
    fetch(encodedurl).then(navigate("/thankyou/"))
  }

  return (
    <div>
      <Container>
        <Helmet
          title="ISN InterNACHI Signup Form - Inspection Support Network"
          defer={false}
        />
        <Header activepage="| Contact" />
      </Container>
      <Container className={'legacy-page-spacer'} fluid>
        <Row
          style={{
            height: "250px",
            backgroundImage:
              'url("https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/cell-phone-in-hand-blurred-1030x425.jpg")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></Row>
      </Container>
      <Container>
        <Row style={{ padding: "10px 0px" }}>
          <hr />
          <Col sm={9}>
            <h3 className="bold">Welcome InterNACHI Member</h3>
            <h4>Lets Get Started: Order ISN for your company</h4>
            <h5>Tell us about your company...</h5>
          </Col>
          <Col sm={3}>
            <img
              alt="internachi-logo"
              src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/internachi.png"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form onSubmit={submitContactForm}>
              <Form.Group controlId="company">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="company"
                  value={company}
                  onChange={e => setCompany(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="phone">
                <Form.Label>Company Phone</Form.Label>
                <Form.Control
                  type="phone"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="web">
                <Form.Label>Company Website</Form.Label>
                <Form.Control
                  type="web"
                  value={web}
                  onChange={e => setWeb(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="address"
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="line2">
                <Form.Label>Line 2</Form.Label>
                <Form.Control
                  type="line2"
                  value={line2}
                  onChange={e => setLine2(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="city"
                  value={city}
                  onChange={e => setCity(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="state"
                  value={state}
                  onChange={e => setState(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="postal">
                <Form.Label>Postal</Form.Label>
                <Form.Control
                  type="postal"
                  value={postal}
                  onChange={e => setPostal(e.target.value)}
                />
              </Form.Group>

              <h3>About You</h3>
              <hr />

              <Form.Group controlId="nachi_id">
                <Form.Label>InterNACHI Member Number</Form.Label>
                <Form.Control
                  type="nachi_id"
                  value={nachi_id}
                  onChange={e => setNachiid(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="first_name">
                <Form.Label>Owner's First Name</Form.Label>
                <Form.Control
                  type="first_name"
                  value={first_name}
                  onChange={e => setFirstname(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="last_name">
                <Form.Label>Owner's Last Name</Form.Label>
                <Form.Control
                  type="last_name"
                  value={last_name}
                  onChange={e => setLastname(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>Owner's Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="message">
                <Form.Label>What else do we need to know...</Form.Label>
                <Form.Control
                  type="message"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                />
              </Form.Group>

              {/*
          <Form.Group controlId="number of inspectors">
            <Form.Label>Number of Inspectors</Form.Label>
            <Form.Control as="select">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
              <option>9</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
              <option>14</option>
              <option>15</option>
              <option>16</option>
              <option>17</option>
              <option>18</option>
              <option>19</option>
              <option>20+</option>
            </Form.Control>
          </Form.Group>
*/}
              <br />
              <Button variant="primary" type="submit">
                Order ISN
              </Button>
            </Form>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  )
}

export default Internachi
